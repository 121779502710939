<script>
import serverConfig   from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';
import { ref } from "vue";
import { useCookies } from "vue3-cookies";
import moment from 'moment';
import { mapActions, mapGetters} from "vuex";


export default {
  name: "warehouseProductsTable",
  props: [
    'data',
    'type',
    'title',
  ],
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    const { cookies } = useCookies();    
    let tk = 'Bearer '+ localStorage.getItem('tk')

    return { active, toggleActive,tk,cookies };
  },
  data() {
	return {
          UrlServer: serverConfig.EP,
          datastore: this.$store.state,
          userData: this.$store.state.auth.currentUser,
          exportLabelsButton:false,
          fileName:'PROVA',
          page: 1,
          perPage: 100,
          sortRes:'asc',
          value: 'All',
          pages: [],
          dbgruppo:'nd',
          paginated:{},
          paginateSettings:false,
          syncroESAction:false,
          refreshTitle:'',
          refreshMsg:'',
          select:'all',
          search:'',
          responseList:[],
          responseListES:false,
          checkPrevSyncValue:false,
          responseGroupFieldList:[],
          n_results:0,
          salesRouteList:[],
          canali:[],
          syncroForce:false,
          item:{
            um:"",
            um2:"",
            Famiglia:"",
            Tipologia:"",
            EAN:"",
            CodArt:"",
            Nome:"",
            slug:"",
            keywords:"",
            tags:"",
            Descrizione:"",
            quantity:0,
            quantity_2:0,
            CodFamiglia:"",
            CodFAM: "",
            CodContoAcquisto:"",
            CodContoVendita: "",
            Produttore: "",
            CodMarca: "",
          },
          filterBy:"-",
          filterByValue:"-",
          base64Str:null,
          decodeBase64:null,
          headerName : {
            name: 'name',
            city: 'city',
            country: 'country',
            birthdate: 'birthdate'
          },
          jsonData: [
          {
            name: 'Tony Peña',
            city: 'New York',
            country: 'United States',
            birthdate: '1978-03-15'
          },
          {
            name: 'Thessaloniki',
            city: 'Athens',
            country: 'Greece',
            birthdate: '1987-11-23'
          }
          ]
        }
	},
	computed:{ 
    ...mapGetters('auth', ['getSR', ])
	},
  mounted(){
    if ( this.$route.query.dbgr && this.$route.query.filterBy && this.$route.query.paginateSettings&&this.$route.query.syncroESAction&&this.$route.query.value){
      this.dbgruppo=this.$route.query.dbgr
      this.filterBy=this.$route.query.filterBy
      this.paginateSettings=this.$route.query.paginateSettings
      this.syncroESAction=this.$route.query.syncroESAction
      this.filterByValue=this.$route.query.value
      this.getFilteredList()
      this.getGroupField()
      this.getFilter()
    }
  },
	methods:{
    ...mapActions('auth', ['getSalesRoute', ]),
    ...mapActions('ecommercesettings', ['addToCart', 'getCartByOrd']),
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
    roundTo(value, decimalpositions)
    {
      var i = value * Math.pow(10,decimalpositions);
      i = Math.round(i);
      return i / Math.pow(10,decimalpositions);
    },
    formatPrice(value) {
      if (value) {
        let val = (value/1).toFixed(2).replace('.',',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      } else {
        return 'n.d.'
      }

    },
    replName(val,el){
      if (val.includes(el)){
        return val.replaceAll(el,' ')
      } else {
        return val
      }
    },
    formatKb(val){
      if (val){
        return this.roundTo(val / 1024, 1)
      } else {
        return ''
      }
    },
    getParent(){
      this.getSearchList()
      
    },
    resetQuery(){
      this.filterBy = '-'
      this.filterByValue = '-'
       //this.getSearchList()
      this.responseList = []
    },
    discCalcBase(array, price){ 
      let slUser = false
      if (this.salesRouteList.length>0) {
        slUser = this.salesRouteList[0].Codice
      }
      let results = array.filter((el)=>{
        return el.Codice == slUser
      })
      if (results.length>0){
        return this.formatPrice(price - ((price*results[0].scontoPercBase)/100))
      }
    },
    discCalcFam(array, price, ){
      let slUser = false
      if (this.salesRouteList.length>0) {
        slUser = this.salesRouteList[0].Codice
      }
      let results = array.filter((el)=>{
        return el.Codice == slUser
      })
      if (results.length>0){
        return this.formatPrice(  (price - ((price*results[0].scontoPercBase)/100)) - (( (price - ((price*results[0].scontoPercBase)/100))*results[0].scontoPercAggiuntivo)/100))
      }
    },      
    getSRLUser(){
      
      if (this.userData){
        /*
        let idsl 
        if (this.userData.registryType == 'main'){
          idsl = this.userData.id
        } else {
          idsl = this.userData.parent
        }
        */
        this.getSalesRouteList(this.userData.id)
      }

    },
    syncroApp() {
        Swal.fire(
          {
            title: this.$t("t-wa-sync-confirm-setting-title"),
            text: this.$t("t-wa-sync-confirm-setting-text"),
            icon: this.$t("t-wa-sync-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-wa-sync-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-wa-sync-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-wa-sync-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-wa-sync-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
          if (result.isConfirmed) {
            this.sendItemsInES()
          }
        });
    },
    
    deletedata(id) {
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
          if (result.isConfirmed) {
            try {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}products/${id}`)
                .then(() => {
                  this.getSearchList()
                  Swal.fire(this.$t("t-msg-deleted") , this.$t("t-msg-data-deleted"), "success");
                })
            }catch(error){
            
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
          }
        });
    },
    getSearchList(){
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}products/search-per-page/`, 
        { 
          params: { 
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            select: this.select,
            search: this.search,
            dbgruppo: this.dbgruppo,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then( response => {
          this.responseList = response.data.results.data;
          let countPrintLabel = response.data.results.data.filter(function(el) {
            return el.printLabel == true
          })
          
          if (countPrintLabel){
            if(countPrintLabel.length>0){
              this.exportLabelsButton = true
            } else {
              this.exportLabelsButton = false
            }
          } else {
            this.exportLabelsButton = false
          }
          let obj = JSON.stringify({"filterBy":this.filterBy,"filterByValue":this.filterByValue,"search":this.search})
          this.base64Str  = Buffer.from(obj).toString('base64')
          this.decodeBase64 = atob(this.base64Str)

          this.paginated = response.data.results
          this.n_results = response.data.n_results
          this.checkItemsInES()

      })
    },
    checkItemsInES(){
      
      axios.post(`${this.UrlServer}esync/check-item-sync`,{items:this.responseList}, 
        { 
          headers: { 'authorization':this.tk},
        },
       ).then( response => {
         console.log(response)
          if (response.data) {
            this.responseListES = response.data;
            let obj = JSON.stringify({
              "filterBy": this.filterBy,
              "filterByValue": this.filterByValue,
              "search": this.search,
              "existentData": this.responseListES.existentData,
              "nonExistentData": this.responseListES.nonExistentData,
              "status": this.responseListES.status,
            })
            let base64Str  = Buffer.from(obj).toString('base64')
            localStorage.setItem('checkitemssync', base64Str)
            //this.checkPrevSync()
            if ( this.responseListES.nonExistentData.length > 0 )
            {
              this.syncroApp() 
            }
          }
      })
    },
    setSyncroField(){
      /*
      let configEs = atob(localStorage.getItem('itemsines'))
      let objConfigEs = JSON.parse(configEs)
      if (objConfigEs) {
        if(Object.keys(objConfigEs).length>0) {
          this.search = objConfigEs.search
          this.filterBy = objConfigEs.filterBy
          this.filterByValue = objConfigEs.filterByValue
          this.page = objConfigEs.page
          this.perPage = objConfigEs.perPage
          this.getGroupField()
          this.responseList = []
          this.getFilteredList()
        }
      }
      */
      let refList = this.responseList
      let listA = []
      for (let item of refList){
        item.syncES = true
        listA.push(item)
      }
      this.responseList = []
      this.responseList = listA
    },
    sendItemsInES(){

      this.checkPrevSyncValue = atob(localStorage.getItem('checkitemssync'))

      if (this.checkPrevSyncValue) {
        let obj = JSON.parse(this.checkPrevSyncValue)
        console.log("obj")
        console.log(obj)
        let dataToEs = []
        console.log("syncroForse")
        console.log(this.syncroForce)
        if (this.syncroForce==false) {
          if (obj.nonExistentData.length>0){
            dataToEs = obj.nonExistentData
          }
         
        } else {
          console.log("syncroForse True")
          console.log(obj.existentData)
          if (obj.existentData.length>0){
            dataToEs = obj.existentData
          }
          
        }
        if (dataToEs.length>0) {
          axios.post(`${this.UrlServer}esync/sync-items`,{items:dataToEs}, 
            { 
              headers: { 'authorization':this.tk},
              onUploadProgress: (event) =>
                {
                  const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                
                  let timerInterval;
                  Swal.fire({
                    title: "Caricamento...",
                    html: "Progresso operazione <b></b>.",
                    timer: percentUploaded,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
                      /*
                      const timer = Swal.getPopup().querySelector("b");
                      
                      timerInterval = setInterval(() => {
                        timer.textContent = `Operazione conclusa`;
                      }, 100);
                      */
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    }
                  }).then((result) => {
                    
                    if (result.dismiss === Swal.DismissReason.timer) {
                      Swal.fire({
                        title:"Inizio operazione",
                        text:"Sincronizzazione avviata",
                      
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:3000,
                      })
                    }
                  });

                },
                onDownloadProgress: (event) =>
                {
                  const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                
                  let timerInterval;
                  Swal.fire({
                    title: "Download data",
                    timer: percentDownloaded,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
                      /*
                        const timer = Swal.getPopup().querySelector("b");

                        timerInterval = setInterval(() => {
                          timer.textContent = `Operazione conclusa`;
                        }, 100);
                      */
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    }
                  }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                      Swal.fire({
                        title:"Esito operazione",
                        text:"Sincronizzazione avvenuta con successo",
                        icon:"success",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:3000,
                      })
                    }
                  });

                }
            },
            
          ).then( response => {
              if (response.data) {
                let obj = JSON.stringify({
                  "items":response.data.items,
                  "results":response.data.results,
                  "status":response.data.status,
                  "filterBy":this.filterBy,
                  "filterByValue":this.filterByValue,
                  "search":this.search,
                  "page":this.page,
                  "perPage":this.perPage,
                })
                let base64Str  = Buffer.from(obj).toString('base64')
                localStorage.setItem('itemsines', base64Str)
              }
              this.responseListES.nonExistentData = []
              this.syncroESAction = true
              //this.$router.push(`/warehouse/products?search=${this.search}&filterBy=${this.filterBy}&filterByValue=${this.filterByValue}`)
              this.setSyncroField()
            
          })
        } else {
          Swal.fire({
            title:"Sincronizzazione dati impossile",
            text:"Mi spiace, ma non sono presenti prodotti per i quali è possibile effettuare la sincronizzazione",
            icon:"warning",
            showCancelButton:false,
            showConfirmButton:false,
            timer:2000,
          })
          this.syncroForce=false
        }

      }
    },
    checkPrevSync(){
      this.checkPrevSyncValue = atob(localStorage.getItem('checkitemssync'))
      let obj = JSON.parse(this.checkPrevSyncValue)
      if (this.checkPrevSyncValue) {
        if (obj.filterBy == this.filterBy && obj.filterByValue==this.filterByValue && obj.search == this.search) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    getFilteredList(){
     
      if(this.filterBy=='CodArt'|| this.filterBy=='Nome') {
          this.filterByValue==this.search
      }
      let obj = JSON.stringify({"filterBy":this.filterBy,"filterByValue":this.filterByValue,"search":this.search})
      this.base64Str  = Buffer.from(obj).toString('base64')
      this.decodeBase64 = atob(this.base64Str)

      if (this.filterByValue!='-'){ 
       
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}products/search-w-filterv`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              filterBy: this.filterBy,
              filterValue: this.filterByValue,
              search: this.search,
              dbgruppo: this.dbgruppo,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then( response => {
            this.responseList = response.data.results.data;
            let countPrintLabel = response.data.results.data.filter(function(el) {
              return el.printLabel == true
            })
            
            if (countPrintLabel){
              if(countPrintLabel.length>0){
                this.exportLabelsButton = true
              } else {
                this.exportLabelsButton = false
              }
            } else {
              this.exportLabelsButton = false
            }
            let obj = JSON.stringify({"filterBy":this.filterBy,"filterByValue":this.filterByValue,"search":this.search})
            this.base64Str  = Buffer.from(obj).toString('base64')
            this.decodeBase64 = atob(this.base64Str)
            this.paginated = response.data.results
            this.n_results = response.data.n_results
            
            this.checkItemsInES()
        })
      }

    },
    resetDBGruppo(){
      this.paginateSettings=true 
      this.syncroESAction=true      
    },
    getFilter(){
     
     if (this.filterBy=='CodArt'|| this.filterBy=='Nome'|| this.filterBy=='printLabel'|| this.paginateSettings==true || this.syncroESAction==true){ 
       this.filterByValue==this.search
       this.responseList = []
      
       let obj = JSON.stringify({"filterBy":this.filterBy,"filterByValue":this.filterByValue,"search":this.search})
       this.base64Str  = Buffer.from(obj).toString('base64')
       this.decodeBase64 = atob(this.base64Str)
       this.axiosInterceptor()
      
       axios.get(`${this.UrlServer}products/search-w-filterv`, 
         { 
           params: { 
             perPage: this.perPage,
             page: this.page,
             sortRes: this.sortRes,
             filterBy: this.filterBy,
             filterValue: this.filterByValue,
             search: this.search,
             dbgruppo: this.dbgruppo,
           }, 
           headers: { 'authorization':this.tk}
         }
       ).then( response => {
         
          this.responseList = response.data.results.data;
          let countPrintLabel = response.data.results.data.filter(function(el) {
            return el.printLabel == true
          }) 
          if (countPrintLabel){
            if(countPrintLabel.length>0){
              this.exportLabelsButton = true
            } else {
              this.exportLabelsButton = false
            }
          } else {
            this.exportLabelsButton = false
          }
          let obj = JSON.stringify({"filterBy":this.filterBy,"filterByValue":this.filterByValue,"search":this.search})
          this.base64Str  = Buffer.from(obj).toString('base64')
          this.decodeBase64 = atob(this.base64Str)
          this.paginated = response.data.results
          this.n_results = response.data.n_results
          this.checkItemsInES()
       })
     }
    },
    exportLabels(){
      if (this.filterByValue!='-'){ 
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}docugen/download-product-code/${this.base64Str}`, 
          {  
            headers: { 'authorization':this.tk}
          }
        ).then( response => {
         
          if (response.headers) {
            if (response.headers["content-type"]=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
             
              Swal.fire({
                title:"Esportazione file avvenuta",
                text:"Esportazione avvenuta con succcesso. Puoi trovare il file excel esportato tra i tuoi download...",
                icon:"success",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2500,
              })
            }
          }
        })
      }
      
    },
    getGroupField(){
      if (this.filterBy!='-'){
       let url 
        if (this.filterBy == 'CodArt'){
          this.responseGroupFieldList = []
          Swal.fire({
                title:"Richiesto Codice Articolo",
                text:"Inserisci il Codice Articolo, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:3500,
              })
              window.stop();
        } else if (this.filterBy=='Nome'){
          
          this.responseGroupFieldList = []
        } else if (this.filterBy=='CodFAM'){
          url = `${this.UrlServer}categories/list`
          this.filterBrandAndCat(url)
        }else if (this.filterBy=='CodMarca'){
          url = `${this.UrlServer}brands/list`
          this.filterBrandAndCat(url)
        }

      }

    },
    filterBrandAndCat(url){
      this.axiosInterceptor()
      axios.get(url, 
            { 
              headers: { 'authorization':this.tk}
            }
          ).then( response => {
            if (response.data){
              this.responseGroupFieldList = response.data
            }
            
            /*
              this.responseGroupFieldList = response.data.sort(function (a, b) {
                if (a._id < b._id) {
                  return -1;
                }
                if (a._id > b._id) {
                  return 1;
                }
                return 0;
              });
            */
      })
    },
    paginateAct(pg){
      this.page = pg
      //this.getSearchList()
      this.paginateSettings = true
      
      this.getFilter()
      
    },
    initItem(){
      this.axiosInterceptor()
      axios.post(`${this.UrlServer}products/register`,this.item, {headers: { authorization:this.tk}} )
        .then((response)=>{
          this.$router.push(`/warehouse/edit-product/${response.data._id}`)
        })
    },
    updatePrintLabel(id,value) {
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}products/${id}`,{"printLabel":value}, {headers: { authorization:this.tk}}).then((response) => {
        if (response) {  
          this.exportLabelsButton = true
          Swal.fire({
            title:"Esportazione etichetta",
            text:"Articolo inserito nella lista delle etichetta da stampare",
            icon:"success",
            showCancelButton:false,
            showConfirmButton:false,
            timer:2500,
          })  
        }
      })
    },
    splitStr(str,car){
        if (str){
          if(str.includes(car)==true) {
            let val =  str.split(car)
            return `${val[0]} ${val[1]}`
          } else{
            return str
          }
        } else {
          return ''
        }
    },
    replAll(str,val1,val2){
      if (str){
        return str.replaceAll( val1, val2)
      } else {
        return '-'
      }
    },
    getSalesRouteList(id){
      if ( localStorage.getItem('tk')) {
        axios.get(`${this.UrlServer}sales-route/srlcompany/${id}`,  
          {headers: { authorization:`Bearer ${ localStorage.getItem('tk') }`}} 
        ).then((response)=>{
          if (response.data){
            this.salesRouteList = response.data
          }
        })   
      }
    },
    salesRouteFilter(prezzoListino, CodMarca,codGruppo){
      let sl = this.salesRouteList.filter(function(el){
        if (el.salesRouteList.length>0){
          return el.salesRouteList[0].CodMarca == CodMarca
        }
      })
      if (sl){
        let label = ''
        let tmp = {}
        let prezzoCalcolato = 0
        tmp["codCanale"] = {label:'Codice Canale', value:sl[0].salesRouteList[0]['SLCode']}
        tmp["tipoCanale"] = {label:'Tipo Canale', value:sl[0].salesRouteList[0]['SLDescription']}
        tmp["scontoBase"] = {label:'Sconto Base', value:sl[0].salesRouteList[0]['RouteDiscountBase']}
        tmp["scontoGruppoProdotto"] = {label:'Sconto sul Prodotto', value:sl[0].salesRouteList[0][codGruppo]}

				if (sl[0].salesRouteList[0]['RouteDiscountBase']>0) {
					// Calcola prezzo applicando lo sconto base
					prezzoCalcolato = prezzoListino - ((prezzoListino*sl[0].salesRouteList[0]['RouteDiscountBase'])/100)
          label +='Sconto Base: '+sl[0].salesRouteList[0]['RouteDiscountBase']+'%'
				}
	
				if (sl[0].salesRouteList[0]['RouteDiscount_2']>0) {
					// Calcola prezzo applicando lo sconto 2
					prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['RouteDiscount_2'])/100)
          label +='<br>Sconto 2: '+sl[0].salesRouteList[0]['RouteDiscount_2']+'%'
				}
				if (sl[0].salesRouteList[0]['RouteDiscount_3']>0) {
          // Calcola prezzo applicando lo sconto 3
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['RouteDiscount_3'])/100)
          label +='<br>Sconto 3: '+sl[0].salesRouteList[0]['RouteDiscount_3']+'%'
				}
	
				// Sconto legato al Gruppo
				let gruppoSconto = sl[0].salesRouteList[0][codGruppo]
				if (gruppoSconto > 0 ) {
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*gruppoSconto)/100)
          label +='<br>Sconto per Gruppo prodotto: '+gruppoSconto+'%'
				}
				// Sconto su gruppo "Agent discount"
				if (sl[0].salesRouteList[0]['AD_MULTI']>0) {
          // Calcola prezzo applicando lo sconto AD_MULTI
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['AD_MULTI'])/100)
          label +='<br>Sconto Agent Multi: '+sl[0].salesRouteList[0]['AD_MULTI']+'%'
        }
        if (sl[0].salesRouteList[0]['AD_SPLIT']>0) {
          // Calcola prezzo applicando lo sconto AD_SPLIT
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['AD_SPLIT'])/100)
          label +='<br>Sconto Agent Split: '+sl[0].salesRouteList[0]['AD_SPLIT']+'%'
        }
        if (sl[0].salesRouteList[0]['AD_VRV_MINI_VRV']>0) {
          // Calcola prezzo applicando lo sconto AD_VRV_MINI_VRV
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['AD_VRV_MINI_VRV'])/100)
          label +='<br>Sconto Agent VRV: '+sl[0].salesRouteList[0]['AD_VRV_MINI_VRV']+'%'
        }
        if (sl[0].salesRouteList[0]['AD_MINI_CHILLER']>0) {
          // Calcola prezzo applicando lo sconto AD_VRV_MINI_VRV
          prezzoCalcolato = prezzoCalcolato - ((prezzoCalcolato*sl[0].salesRouteList[0]['AD_MINI_CHILLER'])/100)
          label +='<br>Sconto Agent MINI CHILLER: '+sl[0].salesRouteList[0]['AD_MINI_CHILLER']+'%'
        }
        label += `<br><span class="badge badge-outline-secondary badge-lm-14">Prezzo: <strong>${this.formatPrice(prezzoCalcolato).toString()} &euro;</strong></span>`
				
        return label
      } else {
        return 'N.D.'
      }

    },
    getCart(){
      if ( localStorage.getItem("orx") ) {
        this.getCartByOrd(localStorage.getItem("orx"))
      }
    },
    addTC(gcode,codMarca, qty){
      moment.locale('it')
      let momentCode = moment().unix()
      let orx = false
     
      if ( localStorage.getItem("orx") ) {
        orx = localStorage.getItem("orx")
      } else {
        localStorage.setItem("orx", orx)
      }
      let sr = false
      for (let item of this.salesRouteList){
        if (item.CodMarca == codMarca){
          sr = item.Codice
        }
      }

      let data = { 
          productCode: gcode,
          userId: this.userData.uid,
          cuuid:momentCode,
          orx:orx,
          salesRoute:sr,
          qta: qty,
      }
      this.addToCart(data)
    },
    testModal(){
      document.getElementById('updateSel').click()
    }
    
  },
  components: {
    
  },
};
</script>
<template>
  <div class="back-grey">
    <div class="card">
      <div class="card-header border-bottom-dashed mb-20">
            <div class="row g-4 align-items-center">
              <div class="col-sm">
                <div>
                
                  <h5 class="card-title mb-0">{{ $t("t-products-list") }}</h5>
                
                </div>
              </div>
              <div class="col-sm-auto">
                <div>
                  <template  v-if="responseListES">
                    <template v-if="responseListES.nonExistentData.length>0">
                    <button type="button" class="btn btn-magenta" @click="syncroApp">
                      <i class="mdi mdi-swap-horizontal align-bottom me-1"></i>
                        Sincronizzazione prodotti
                    </button>
                     </template>
                     <template v-else>
                    <template v-if="syncroForce==false">
                      <a href="javascript:void(0)" @click="syncroForce=true" class="pr-5">Forza Sincronizzazione</a>
                    </template>
                    <template v-else>
                      <a href="javascript:void(0)" @click="syncroForce=false" class="pr-5">Annulla</a>
                      <button type="button" class="btn btn-danger" @click="syncroApp">
                      <i class="mdi mdi-swap-horizontal align-bottom me-1"></i>
                        Forza Sincronizzazione
                    </button>
                    </template>

                  </template>
                  </template>

                  &nbsp;
                  <button type="button" class="btn btn-primary add-btn me-1" data-bs-toggle="modal" id="create-btn"
                    @click="initItem">
                    <i class="mdi mdi-note-plus align-bottom me-1"></i> Aggiungi prodotto
                  </button>

                </div>
              </div>
            </div>
          </div>
          <div class="card-body border-bottom-dashed border-bottom mb-20">
            <form>
              <div class="row rowSearchFormBGce g-4 mb-50">
                <div class="col-lg-4 col-md-12">
                  <div class="">
                    <div class="search-box ms-2">
                      <input type="text" class="form-control" placeholder="Cerca prodotto..." v-model="search" @input="resetQuery()" />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-12">
                  <select class="form-select" v-model="dbgruppo" @change="resetDBGruppo">
                    <option value="nd">Seleziona Magazzino Azienda</option>
                    <option value="SP">Sunservice</option>
                    <option value="ES">S.Energy Solutions</option>
                  </select>
                </div>
                <div class="col-lg-2 col-md-12">
                  <select class="form-select" v-model="filterBy" @change="getGroupField">
                    <option value="-"> Filtra per:</option>
                    <option value="CodArt">Codice Articolo</option>
                    <option value="Nome">Nome Articolo</option>
                    <option value="CodFAM">Famiglia (Tipologia)</option>
                    <option value="CodMarca"> Marca</option>
                    <option value="printLabel">Solo con etichetta da stampare</option>
                  </select>
                </div>
                <div class="col-lg-2 col-md-12">
                  <template v-if="responseGroupFieldList.length>0">
                    <select class="form-select" v-model="filterByValue" @change="getFilteredList">
                      <option value="-"> Seleziona</option>  
                      <option v-for="item in responseGroupFieldList" :key="item._id" :value="item.code"> {{ item.name }}</option>        
                    </select>
                  </template>
                  <template v-else>
                    <select class="form-select"  readonly="readonly">
                    </select>
                  </template>
                </div>
                <div class="col-lg-1 col-md-12">
                  <button type="button" class="btn btn-primary w-100" @click="getFilter()" >
                    <i class="ri-equalizer-fill me-1 align-bottom"></i>
                      Filtra
                    </button>
                </div>
              </div>
              <!--end row-->
            </form>
          </div>
            <div class="card-header">
              <div class="row align-items-center mb-50">
                <div class="col-lg-8 col-md-12 ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Referenze
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-2 col-md-12 ">
                  </div>
                <div class="col-lg-2 col-md-12 ">
                  <template v-if="exportLabelsButton==true">
                    <b-button variant="primary" class="btn-label waves-effect waves-light"  >
                      <i class="ri-printer-line label-icon align-middle fs-16 me-2"></i>
                      <a :href="`${UrlServer}docugen/download-product-code/${base64Str}`" class="text-white">
                     
                        Esporta Etichette
                      </a> 
                    </b-button>
                  </template>
                  <div id="selection-element">
                    <div class="my-n1 d-flex align-items-center text-muted">

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
            <div class="card-body mt-20">
                <div>
                
                <div class="mb-50">
                 <template  v-if="responseListES">
                    <template v-if="responseListES.nonExistentData.length>0">
                      <b-alert show variant="danger">
                        <strong> Attenzione: </strong> ci sono ancora <b>{{ responseListES.nonExistentData.length }}</b> articoli che non sono stati inviati inviati al gestionale ESolver!
                      </b-alert>
                    </template>
                 </template>
                 
                </div>



                  <div class="table-card mb-1">
                    <div class="row thRow  ">
                    <div class="col text-left">
                       Nome
                    </div>
                    <div class="col text-left">
                      Codice
                    </div>
                    <div class="col text-left">
                      Famiglia
                    </div>
                    <div class="col text-left">
                      Giacenza
                    </div>   
                    <div class="col text-left">
                      Sincronizzato con ESolver
                    </div>
                    <div class="col text-left">
                      Magazzino
                    </div>
                    <div class="col text-left">
                      Esporta Etichetta
                    </div>                 
                    <div class="col text-left">
                        Azioni
                    </div>
                </div>
                <div class="row list tRow form-check-all d-flex  " v-for=" (item, index) of responseList" :key="index">
                  <div class="col text-left">
                    <template v-if="item.Nome">
                      <template v-if="item.Nome!=''">{{ item.Nome }}</template>
                      <template v-if="item.Descrizione!='' && item.Nome==''">{{ item.Descrizione }}</template>
                    </template>
                    <template v-else>
                      {{ item.Descrizione }}
                    </template>
                  </div>
                  <div class="col text-left"  v-html="item.CodArt">
                  </div>
                  <div class="col text-left">
                    <template v-if="item.DescFAM">
                       <strong> {{ item.DescFAM }} </strong>
                    </template>
                    <template v-if="item.CodFAM">
                      <br>  {{ item.CodFAM }}
                    </template>
                  </div>
                  <div class="col text-left"  v-html="item.giacenzaEffettiva">
                  </div>
                  <div class="col text-left" >
                    <template v-if="item.syncES">
                      <template v-if="item.syncES==true">  
                        <span class="mdi mdi-24px mdi-check text-green pr-5 "></span>
                      </template>
                      <template v-else>
                        <span class="mdi mdi-24px mdi-close-thick text-red pr-5 "></span>
                      </template>
                    </template>
                    <template v-else>
                      <span class="mdi mdi-24px mdi-close-thick text-red pr-5 "></span>
                    </template>
                  </div>
                  <div class="col text-left" >
                    
                    <template v-if="item.DBGruppo">
                      <template v-if="item.DBGruppo=='SP'">
                        Sunservice
                      </template>
                      <template v-else-if="item.DBGruppo=='ES'">
                        S.Energy Solutions
                      </template>
                      <template v-else>
                        N.D.
                      </template>
                    </template>
                    <template v-else>
                      N.D.
                    </template>
                  </div>
                  <div class="col text-left" >
                    <div class="form-check form-switch form-switch-info">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="item.printLabel" @change="updatePrintLabel(item._id,item.printLabel)" >
                    </div>
                  </div>
                  <div class="col text-left ">
                    <ul class="list-inline hstack gap-2 mb-0">
                        <template v-if="!item.syncES">
                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Rimuovi">
                            <a href="javascript:void(0)"  class="text-danger d-inline-block remove-item-btn" @click="deletedata(item._id)">
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                        </template>

                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Modifica scheda">
                            <a href="javascript:void(0)"  @click="$router.push(`/warehouse/edit-product/${ item._id }?dbgr=${item.DBGruppo}&filterBy=${this.filterBy}&paginateSettings=true&syncroESAction=true&value=${this.filterByValue}`)">
                              <i class="mdi mdi-comment-edit-outline fs-16"></i>
                            </a>
                          </li>
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Visualizza">
                            <a href="javascript:void(0)"  @click="$router.push(`/warehouse/product-details/${ item._id }?dbgr=${item.DBGruppo}&filterBy=${this.filterBy}&paginateSettings=true&syncroESAction=true&value=${this.filterByValue}`)">
                              <i class="mdi mdi-format-float-none fs-16"></i>
                            </a>
                          </li>
                        </ul>
                  </div>
                </div>

              </div>
              <div class="d-flex tRow justify-content-end mt-3 mb-50" v-if="n_results > perPage">
                    <div class="col-lg-2 col-md-12 text-right">
                        <label class="pt-2">Risultati per pagina:</label>
                    </div>        
                    <div class="col-lg-1 col-md-12 ">
                        <div class="pl-10 pr-20">
                            <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index" >
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div>

              <!--x-->
              </div>
            </div>
            
        </div>
  </div>
</template>